@import "src/scss/module";

.header {
  padding: 0;
  overflow-y: scroll;
  max-height: 100vh;

  @include media-breakpoint-up(md) {
    overflow-y: initial;
  }

  &__brand {
    padding: $spacer * 0.25;

    img {
      height: 60px;
      object-fit: contain;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 !important;

      img {
        height: 70px;
      }
    }
  }

  .navItem {
    .navLink {
      color: $black;
    }

    &:focus,
    &:hover {
      background-color: rgba($black, 0.025);
    }
  }

  :global {
    .navbar {
      &-toggler {
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 1.5em;
          height: 1.5em;

          span {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            height: 3px;
            width: 100%;
            background-color: rgba(var(#{--bs-emphasis-color-rgb}), 0.15);
          }
        }
      }

      &-collapse.show {
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(sm) {
          box-shadow: none;
        }
      }

      &-nav {
        min-height: 83px;

        & > .dropdown {
          & > .nav-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            @include media-breakpoint-up(lg) {
              justify-content: space-around;
            }

            &:focus,
            &:hover {
              background-color: rgba($black, 0.025);
            }

            & > .nav-link {
              // text-transform: capitalize;

              white-space: nowrap;
              padding: $spacer;
              color: $black !important;
            }

            & > .dropdown-toggle {
              display: flex;
              align-items: center;
              gap: $spacer * 0.5;
              justify-content: center;
              background-color: transparent !important;
              border: 0 !important;
              padding: $spacer $spacer $spacer 0;
              transition: none !important;

              &:after {
                display: none;
              }

              & > svg {
                stroke: var(#{--bs-secondary}) !important;
              }
            }
          }

          & > .dropdown-menu {
            border: none;
            border-radius: 0;
            margin-top: 0;
            padding: 0;

            & > .nav-item {
              display: flex;
              align-items: center;

              &:not(:first-child) {
                border-top: 1px solid rgba($white, 0.25);
              }
            }

            .nav-link {
              color: inherit;

              padding: $spacer * 0.75 $spacer * 1.5;
              white-space: nowrap;
            }
          }
        }

        & > .nav-item {
          display: flex;
          align-items: center;

          & > .nav-link {
            white-space: nowrap;
            padding: $spacer;
          }
        }
      }
    }
  }
}
